var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota__details" },
    [
      _vm._l(_vm.sample.child_templates, function (item) {
        return _c("div", { key: item.id, staticClass: "quota__details-item" }, [
          _c("div", { staticClass: "sample__main mod-child" }, [
            _vm._v(
              " " + _vm._s(_vm.getSupplierTitle(item.suppliers_ids[0])) + " "
            ),
          ]),
          _c("div", { staticClass: "sample__progressbar" }, [
            _c("div", { staticClass: "sample__progressbar-info" }, [
              _c("div", { staticClass: "info__title" }, [
                _vm._v("Тайм слоты:"),
              ]),
              _c("div", { staticClass: "info__value" }, [
                _vm._v(" " + _vm._s(item.trucks_allowed_count) + " "),
              ]),
            ]),
          ]),
        ])
      }),
      _c("div", { staticClass: "quota__details-item" }, [
        _c("div", { staticClass: "quota__main mod-child mod-unused" }, [
          _c("div", [
            _c("span", [_vm._v("Нераспределенные таймслоты: ")]),
            _c("span", { staticClass: "title__unimproved" }, [
              _vm._v(_vm._s(_vm.getUnimprovedQuota)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "quota__progressbar" }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }