<template>
  <div class="sample">
    <div class="sample-content">
      <div :class="['sample__main', { improved: isCompletelyImproved }]">
        <div>
          <span class="title__exporter">
            {{ `Экспортёр: ${getQuotaExporter}  /` }}
          </span>
          <span class="title__description">{{ getTitle }}</span>
          <Icon name="IconTruckIconQuota" width="15px" height="11px" />).
        </div>

        <div
          v-if="!isMobile"
          :class="[
            'sample-content__icon-wrap',
            { support: isSupport || isViewer },
          ]"
        >
          <Icon
            v-if="!isSupport && !isViewer"
            name="IconPen"
            width="20px"
            height="18px"
            @onClick="handleEditCulture"
          />
          <Icon
            v-if="!isSupport && !isViewer"
            name="IconTrash"
            width="15px"
            height="18px"
            @onClick="handleDeleteCulture(sample)"
          />
          <Icon
            name="ArrowDownIcon"
            width="22px"
            height="22px"
            :class="['arrow', { rotated: isOpen }]"
            @onClick="isOpen = !isOpen"
          />
        </div>
      </div>

      <div class="sample__progressbar">
        <div class="sample__progressbar-info">
          <div class="info__title">Тайм слоты:</div>
          <div class="info__value">
            {{ sample.trucks_allowed_count }}
          </div>
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <SampleShared v-show="isOpen && !isMobile" :sample="sample" />
    </el-collapse-transition>
    <DialogDeleteSample />
  </div>
</template>

<script>
import { DIALOG_ADD_SAMPLE, QUOTA_DELETE_SAMPLE } from '@/constants/dialogs'
import {
  GET_EXPORTERS_ALL,
  GET_PREPARE_CULTURE_FROM_STATE,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE } from '@/store/actions'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { mapGetters } from 'vuex'
import DialogDeleteSample from '@/views/quota-samples/components/dialogs/dialogDeleteSample/DialogDeleteSample.vue'
import Icon from '@/UI/icon/Icon'
import SampleShared from './SampleShared.vue'

export default {
  name: 'SampleContent',
  components: {
    SampleShared,
    DialogDeleteSample,
    Icon,
  },
  props: {
    sample: {
      type: Object,
      required: true,
    },
    del: {
      type: Boolean,
      required: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      visibleDeleteModal: false,
      visibleDeleteNonSlotModal: false,
    }
  },
  computed: {
    ...mapGetters({
      culturesList: GET_PREPARE_CULTURE_FROM_STATE,
      exportersList: GET_EXPORTERS_ALL,
      isMobile: GET_IS_MOBILE,
    }),
    getTitle() {
      return `${this.getQuotaCultures} по ${this.sample.trucks_per_hour}
              авто в час${this.getQuotaTypeAuto}`
    },
    getQuotaCultures() {
      return (
        this.sample.cultures_ids.reduce((accum, item) => {
          if (this.culturesList[item]?.name) {
            accum += `${this.culturesList[item]?.name.trim()}${
              this.culturesList[item]?.harvest_year
                ? ' / год урожая: ' + this.culturesList[item]?.harvest_year
                : ''
            },`
          }

          return accum
        }, '') || 'Неизвестная культура,'
      )
    },
    getQuotaExporter() {
      let exporter = this.exportersList.find(
        item => item.id === this.sample.exporters_ids[0],
      )

      return exporter ? exporter.name : 'не указан'
    },
    getQuotaTypeAuto() {
      if (
        this.sample.truck_type_codes.length === autoTypeSupervisor.length ||
        !this.sample.truck_type_codes.length
      ) {
        return ', (любые).'
      }
      let typeNames = []

      this.sample.truck_type_codes.forEach(item => {
        typeNames.push(autoTypeSupervisor.find(name => name.id === item).name)
      })

      return `, ${typeNames.join(', ')}`
    },
    isCompletelyImproved() {
      if (!this.sample.child_templates || !this.sample.child_templates.length) {
        return false
      }

      let improved = this.sample.child_templates.reduce(
        (sum, item) => sum + item.trucks_allowed_count,
        0,
      )

      return improved === this.sample.trucks_allowed_count
    },
  },
  watch: {
    del: {
      handler() {
        this.handleDeleteCulture()
      },
    },
    edit: {
      handler() {
        this.handleEditCulture()
      },
    },
  },
  methods: {
    handleDelete(data) {
      this.setDialog({ name: QUOTA_DELETE_SAMPLE, visible: true, data })
    },

    handleEditCulture() {
      this.setDialog({
        name: DIALOG_ADD_SAMPLE,
        data: { ...this.sample, isEdit: true },
        visible: true,
      })
      this.$emit('emitClose')
    },
    handleDeleteCulture() {
      this.handleDelete({
        id: this.sample.id,
        count: this.sample.count,
      })
      this.$emit('updateBeforeDelete')
      this.$emit('emitClose')
    },
  },
}
</script>

<style lang="sass">
.sample
  flex: 1 0 71%

.sample-content
  display: flex
  justify-content: space-between
  align-items: center

  &__icon-wrap
    flex: 0 0 87px
    display: flex
    align-items: center
    margin-right: 15px
    &.support
      flex: 0 0 29px
      margin-right: 0
    .arrow
      transition: transform 0.3s ease
      &.rotated
        transform: rotate(180deg)
      &.iq-icon:hover *
        fill: none
        stroke: $color-blue-primary

  &__icon
    font-size: 20px
    cursor: pointer
    color: $color-black
    transition: color .3s ease
    & + &
      margin-left: 10px
    &:hover
      color: $color-blue

  .iq-icon
    cursor: pointer
    *
      transition: fill .3s ease, stroke .3s ease
    &:hover *
      fill: $color-blue-primary

.sample__main
  flex: 1
  display: flex
  justify-content: space-between
  align-items: center
  margin-right: 14px
  padding: 9px 13px
  background: #F0F1F7
  border: 1px solid #DCDCDC
  border-radius: 8px
  font-weight: 500
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.5px
  &.improved
    background: #F0F9EB
  &.mod-child
    flex: 2 0 223px
  .title__exporter
    color: $grey-font
  .title__description
    color: $main-font
  .title__unimproved
    font-weight: 900
    color: $color-orange-primary

.sample__progressbar
  flex: unset
  .el-progress-bar__outer
    border: 1px solid #EBEEF5
  .el-progress-bar__innerText
    font-size: 0
  &-info
    display: flex
    justify-content: flex-end
    align-items: baseline
    font-size: 16px
    line-height: 19px
    letter-spacing: 0.5px
    color: $main-font
    margin-bottom: 2px
    .info__title
      width: 100px
    .info__value
      width: 30px
      font-weight: 900
      letter-spacing: 0.5px
      text-align: right
      color: $color-orange-primary

@media (max-width: 1200px)
  .sample-content
    flex-direction: column
    flex: auto
    align-items: flex-start
    width: 100%
    .sample__main
      flex: auto
      margin-right: 0
      border: none
      background: #fff
      padding: 0
      margin-bottom: 16px
      &.improved
        background: #F0F9EB
    .sample__progressbar
      flex: auto
      width: 100%
    &__main-ts
      justify-content: space-between
      font-size: 12px
      margin-bottom: 0
      &-count
        font-size: 12px
</style>
