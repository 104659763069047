var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sample" },
    [
      _c("div", { staticClass: "sample-content" }, [
        _c(
          "div",
          { class: ["sample__main", { improved: _vm.isCompletelyImproved }] },
          [
            _c(
              "div",
              [
                _c("span", { staticClass: "title__exporter" }, [
                  _vm._v(
                    " " + _vm._s(`Экспортёр: ${_vm.getQuotaExporter} /`) + " "
                  ),
                ]),
                _c("span", { staticClass: "title__description" }, [
                  _vm._v(_vm._s(_vm.getTitle)),
                ]),
                _c("Icon", {
                  attrs: {
                    name: "IconTruckIconQuota",
                    width: "15px",
                    height: "11px",
                  },
                }),
                _vm._v("). "),
              ],
              1
            ),
            !_vm.isMobile
              ? _c(
                  "div",
                  {
                    class: [
                      "sample-content__icon-wrap",
                      { support: _vm.isSupport || _vm.isViewer },
                    ],
                  },
                  [
                    !_vm.isSupport && !_vm.isViewer
                      ? _c("Icon", {
                          attrs: {
                            name: "IconPen",
                            width: "20px",
                            height: "18px",
                          },
                          on: { onClick: _vm.handleEditCulture },
                        })
                      : _vm._e(),
                    !_vm.isSupport && !_vm.isViewer
                      ? _c("Icon", {
                          attrs: {
                            name: "IconTrash",
                            width: "15px",
                            height: "18px",
                          },
                          on: {
                            onClick: function ($event) {
                              return _vm.handleDeleteCulture(_vm.sample)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("Icon", {
                      class: ["arrow", { rotated: _vm.isOpen }],
                      attrs: {
                        name: "ArrowDownIcon",
                        width: "22px",
                        height: "22px",
                      },
                      on: {
                        onClick: function ($event) {
                          _vm.isOpen = !_vm.isOpen
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "sample__progressbar" }, [
          _c("div", { staticClass: "sample__progressbar-info" }, [
            _c("div", { staticClass: "info__title" }, [_vm._v("Тайм слоты:")]),
            _c("div", { staticClass: "info__value" }, [
              _vm._v(" " + _vm._s(_vm.sample.trucks_allowed_count) + " "),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-collapse-transition",
        [
          _c("SampleShared", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && !_vm.isMobile,
                expression: "isOpen && !isMobile",
              },
            ],
            attrs: { sample: _vm.sample },
          }),
        ],
        1
      ),
      _c("DialogDeleteSample"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }